<template>
    <div class="m-wrapper04">
        <div class="m-doctor-info03">
            <h5 class="hospital-name03">福建省立医院</h5>
            <div class="doctor-info-box04">
                <span>消化内科</span>
                <span>郑铭</span>
            </div>
        </div>
        <div class="content-modular01">
            <p>放号时间：15:00</p>
            <p>提前天数：就诊前 14 天</p>
            <div class="tip01">*上述放号时间仅作为参考，具体以医院实际放号情况为准。</div>
        </div>
        <div class="m-doctor-info03">
            <h5 class="hospital-name03">医院预约规则说明</h5>
        </div>
        <div class="content-modular01">
            <p>
                1.取号时间：预约时段内早上7点半之后取号，下午14点之后取号，迟到15分钟作废。 2.取号地点：科室门口自助机上 3.退号时间：上午截止就诊日00：00前，下午截止就诊日12：00前 4.违约限制规则：违约2次限制预约3个月，恢复后如3个月内违约2次，将限制预约1年。 
            </p>
        </div>
        <div class="m-doctor-info03">
            <h5 class="hospital-name03">平台预约规则说明</h5>
        </div>
        <div class="content-modular01">
            <p>
                为有效防控号贩子，避免有限的医生号源浪费，确保有序公平的预约环境，让确实有需求的患者受益。现本平台对预约和退号频次达到一定数量的账户将采取限制措施，措施为：每个账号每个月下单满10笔且退号满5笔时限制该账户当月预约权限，可正常退号（如有疑问可申诉4006677400）。 
            </p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            hospitalId: ''
        }
    },
    mounted() {
        // 获取地址栏传递过来的医院ID
        this.hospitalId = this.$route.query['id']
        // 看下接口是如何设计的如何获取到医院的预约规则信息
    }
}
</script>
<style lang="scss" scoped>
.m-wrapper04 {
    height: 100vh;
    background: #fff;
    text-align: left;
}
</style>